import {
  createAxiosLikeFetch,
  type AxiosLikeFetchResponse,
} from '@devhacker/shared/libs/fetch/createAxiosLikeFetch';
import { createLoggerInterceptors } from '@devhacker/shared/libs/logger';
import { REACTIONS_API_URL } from '~/constants/config';
import { TIMEOUT } from '~/constants';
import { authErrorHandler } from '~/utils/errorHandlerAuth';
import { authRequestInterceptor } from '~/utils/interceptorsRequestAuth';

export interface ReactionsApi {
  fetchReactions: (ids: number[]) => Promise<
    AxiosLikeFetchResponse<{
      data: ReactionFromServer[];
      success: boolean;
    }>
  >;
  updateReaction: (
    id: number,
    user_state: TReactionType,
    isHealth: boolean,
  ) => Promise<
    AxiosLikeFetchResponse<{
      data: ReactionFromServer;
      success: boolean;
    }>
  >;
  updateLike: (id: number) => Promise<
    AxiosLikeFetchResponse<{
      data: ReactionFromServer;
      success: boolean;
    }>
  >;
  updateDislike: (id: number) => Promise<
    AxiosLikeFetchResponse<{
      data: ReactionFromServer;
      success: boolean;
    }>
  >;
}

const getUserIdFromLocalStorage = () => {
  if (import.meta.client) {
    try {
      return JSON.parse(localStorage.getItem('XUserId') || '0');
    } catch (e) {
      console.error(e);
    }
  }
};

const addXUserIdHeader = (config: any) => {
  if (import.meta.client) {
    const xUserId = getUserIdFromLocalStorage();
    // TODO: заменить X-UserId на X-User-Id после релиза избы
    config.options.headers['X-UserId'] = xUserId;
  }
};

export const createReactionsApi = ({ $logger, $store, route }: any): ReactionsApi => {
  const loggerInterceptors = createLoggerInterceptors($logger, 'reactions');
  const reactionsFetch = createAxiosLikeFetch({
    baseURL: REACTIONS_API_URL,
    timeout: TIMEOUT,
    onRequest: [
      loggerInterceptors.onRequest,
      addXUserIdHeader,
      (config) => authRequestInterceptor(config),
    ],
    onRequestError: [
      loggerInterceptors.onRequestError,
      (config) => authErrorHandler(config, route),
    ],
    onResponse: loggerInterceptors.onResponse,
    onResponseError: [
      loggerInterceptors.onResponseError,
      (config) => authErrorHandler(config, route),
    ],
  });

  return {
    fetchReactions: (ids) => {
      const url = '/api/v1/posts';
      const params = { post_ids: ids.join() };
      return reactionsFetch(url, { params });
    },
    updateReaction: (post_id: number, user_state: TReactionType, isHealth: boolean = false) => {
      const url = '/api/v1/posts';
      const body = {
        post_id,
        user_state,
        ...(isHealth && { post_type: 'health' }),
      };
      return reactionsFetch(url, { method: 'post', body });
    },
    // используется только в рецептах
    updateLike: (id: number) => {
      const url = '/api/v1/posts';
      const body = {
        post_id: id,
        user_state: 'like',
      };
      return reactionsFetch(url, { method: 'post', body });
    },
    // используется только в рецептах
    updateDislike: (id: number) => {
      const url = '/api/v1/posts';
      const body = {
        post_id: id,
        user_state: 'dislike',
      };
      return reactionsFetch(url, { method: 'post', body });
    },
  };
};
